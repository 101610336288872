@charset 'UTF-8';



// メディアクエリの書き出し
//
@mixin media( $data )
{
  $key: map-get( $breakpoints, $data );
  @if( $data == 'xs' ){
    $key: map-get( $breakpoints, sm ) - 1px;
    @media screen and ( max-width: $key ) {
      @content;
    }
  } @else {
    @media screen and ( min-width: $key ) {
      @content;
    }
  }
}



// Container
@mixin make-container
{
  margin: 0 auto;
  padding: 0 $base-side-padding-xs;
  max-width: getMaxWidth( xs );
  @include media(sm){
    margin: 0 auto;
    padding: 0 $base-side-padding-sm;
    max-width: getMaxWidth( sm );
  }
  @include media(md){
    padding: 0 $base-side-padding-md;
    max-width: getMaxWidth( md );
  }
  @include media(lg){
    padding: 0 $base-side-padding-lg;
    max-width: getMaxWidth( lg );
  }
  @include media(xl){
    padding: 0 $base-side-padding-xl;
    max-width: getMaxWidth( xl );
  }
}

@mixin max-container
{
  margin: 0 auto;
  padding: 0 $base-side-padding-xs;
  @include media(sm){
    margin: 0 auto;
    padding: 0 $base-side-padding-sm;
  }
  @include media(md){
    padding: 0 $base-side-padding-md;
  }
  @include media(lg){
    padding: 0 $base-side-padding-lg;
  }
  @include media(xl){
    padding: 0 $base-side-padding-xl;
  }
}



// grid
//
@mixin make-grid( $width )
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}



// make grid items
//
@mixin make-grid-items( $key, $num )
{
  .#{$key}-#{$num} {
    @include make-grid( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0;
  }
  .#{$key}-#{$num}-center {
    @include make-grid( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0 auto;
  }
  .#{$key}-offset-#{$num} {
    @include make-grid( math.div(100%, math.div($grid-columns, $num)) );
    margin-left: ( math.div(100%, math.div($grid-columns, $num)) );
  }
  .#{$key}-col-#{$num} {
    @include make-grid( math.div(100%, $num) );
    $pad: math.div(map-get($grid-gutters, $key), 2);
    margin: 0;
    padding: #{$pad};
  }
}
