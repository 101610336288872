@charset 'UTF-8';

// Font family
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;500;700;900&display=swap');

$font-family-sans: 'YakuHanJPs', -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;
$font-family: 'YakuHanJPs', 'Noto Serif JP', '游明朝体', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', 'MS P明朝', 'MS PMincho', serif;

// Base text color
$base-text-color: $gray-dark !default;

// Base background color
$base-background-color: $white !default;

// Base font size
$base-font-size: 1rem !default; // default font size 1rem = 10px

$font-size-xs: $base-font-size * 1.4 !default;
$font-size-sm: $base-font-size * 1.4 !default;
$font-size-md: $base-font-size * 1.5 !default;
$font-size-lg: $base-font-size * 1.6 !default;
$font-size-xl: $base-font-size * 1.6 !default;

$font-size-array: (
  'xsmall'  : 62.5%,
  'small'   : 75%,
  'medium'  : 87.5%,
  'large'   : 112.5%,
  'xlarge'  : 125%,
  'xxlarge' : 150%,
  'exlarge' : 200%
) !default;

$base-line-height: 1.6 !default;

// breakpoint ごとの比率
$scale-sm: math.div($font-size-sm, $font-size-xs);
$scale-md: math.div($font-size-md, $font-size-xs);
$scale-lg: math.div($font-size-lg, $font-size-xs);
$scale-xl: math.div($font-size-xl, $font-size-xs);

// Heading
$h1-font-size: 2.25em !default;
$h2-font-size: 1.625em !default; // 2em
$h3-font-size: 1.625em !default; // 1.75em
$h4-font-size: 1.5em !default;
$h5-font-size: 1.25em !default;
$h6-font-size: 1em !default;

$heading-margin-bottom: .5em !default;
$heading-line-height:   1.4 !default;

// Paragraph .lead
$lead-letter-spacing-xs: 0 !default;
$lead-letter-spacing-sm: 0 !default;
$lead-letter-spacing-md: 0 !default;
$lead-letter-spacing-lg: 0 !default;
$lead-letter-spacing-xl: 0 !default;

$lead-line-height-xs: 1.8 !default;
$lead-line-height-sm: 1.8 !default;
$lead-line-height-md: 1.8 !default;
$lead-line-height-lg: 1.8 !default;
$lead-line-height-xl: 1.8 !default;

$lead-top-spacer-xs: 1em !default;
$lead-top-spacer-sm: 1em !default;
$lead-top-spacer-md: 1em !default;
$lead-top-spacer-lg: 1em !default;
$lead-top-spacer-xl: 1em !default;

$base-transition: .2s ease-out;
$base-box-shadow: 0 .5rem 1rem rgba( 0, 0, 0, .15 );

// Links
$link-color:            $base !default;
$link-decoration:       underline !default;
$link-hover-color:      darken( $link-color, 15% ) !default;
$link-hover-decoration: underline !default;

// Horizontal line
$hr-border: 1px solid $gray-400 !default;

// Table
$table-border-color:        $gray-300 !default;
$table-th-background-color: #f7f4f3 !default;
$table-td-background-color: $white    !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29'),
) !default;
